import React, { useState } from "react"
import Layout from "../../Components/pageComponents/contentContainer"
import { Container, Fade } from "reactstrap"
import Img from "gatsby-image"

import { useStaticQuery, graphql } from "gatsby"
import { Button } from "reactstrap"

// import directory from "../../content/directory.json"

import style from "./styles.module.css"

export default function AudioAndVideo() {

  const Image = useStaticQuery(graphql`
    query {
      banner: file(relativePath: {eq: "solutions/Audio Video/Audio and Video Banner.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      auditorium: file(relativePath: {eq: "solutions/Audio Video/Theater and Auditorium Stock.jpg"}) {
        childImageSharp {
          fixed(width: 365) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      conference: file(relativePath: {eq: "solutions/Audio Video/Conference Room Stock.png"}) {
        childImageSharp {
          fixed(width: 350) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }`
  )

  const [fadeIn, setFadeIn] = useState(true);
  const [fadeIn2, setFadeIn2] = useState(true);

  const toggle = e => {
    setFadeIn(!fadeIn);
    e.target.innerHTML = fadeIn ? 'Hide' : 'Learn More';
  }

  const toggle2 = e => {
    setFadeIn2(!fadeIn2);
    e.target.innerHTML = fadeIn2 ? 'Hide' : 'Learn More';
  }

  return (
    <Layout image={Image.banner.childImageSharp.fluid}
      title="Audio and Video">
      <Container
        fluid
        // className="pt-5 pb-5 pl-5 pr-5"
        style={{
          background: `rgb(243, 243, 243)`,
          paddingTop: `2rem`,
          paddingBottom: `3rem`,
        }}
      >
        <h1 className="mx-auto mb-4 heading1 p-3 text-center">
          <span>Solutions we offer</span>
        </h1>
        <div className={style.audioVideoContainer}>
          <div className={style.audioVideoContent}>
            <h2>Conference Rooms</h2>
            <Fade in={fadeIn} className={fadeIn === true ? style.show : style.hidden}>
              <Img fixed={Image.conference.childImageSharp.fixed} />
            </Fade>
            <Fade in={!fadeIn} tag="p" className={fadeIn === true ? style.hidden : style.show}>
              A Conference room is where ideas are created, shared, and planned. It is of utmost importance to equip a Conference Room with the proper technology to enhance the users’ experience, productivity, and efficiency.
                    <br /><br />
                    From Enterprise Webcams to advanced Video Bars equipped with tri-needs of Conferencing – Mic, Speaker, and Camera – and other additional features such as multimedia capable and mobile configurations, Fastlink is capable of offering these products and services with outstanding support.

                  </Fade>
            <Button className={style.button1} onClick={toggle}>Learn More</Button>
            <div>

            </div>
          </div>

          <div className={style.audioVideoContent}>
            <h2>Theater and Auditorium</h2>
            <Fade in={fadeIn2} className={fadeIn2 === true ? style.show : style.hidden}>
              <Img fixed={Image.auditorium.childImageSharp.fixed} />
            </Fade>
            <Fade in={!fadeIn2} tag="p" className={fadeIn2 === true ? style.hidden : style.show}>
              Gear up the Multimedia Room with our Theater and Auditorium Solution.
                  <br /><br />
                  Fastlink is capable of providing a complete set of entertainment Audio
                  Video with the clearest sound and highest video definition. Whether
                  for home theaters or Campus Wide Auditoriums, Fastlink’s excellent
                  support and services can guarantee a one of a kind experience with AV.
                  </Fade>
            <Button className={style.button1} onClick={toggle2}>Learn More</Button>

          </div>
        </div>
      </Container>

    </Layout>

  )
}