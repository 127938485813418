import React from "react"
import BackgroundImage from 'gatsby-background-image'


import style from "./styles.module.css"

const Banner = ({image, title}) => {
    return (
        <BackgroundImage
            Tag="section"
            className={style.banner}
            fluid={image}
        >
            <div>
                <h1>{title}</h1>
            </div>
        </BackgroundImage>
    )
}

export default Banner
