import React from "react"
import Layout from "../../Components/layout"
// import { Row, Col } from "reactstrap"
import Banner from "../../Components/pageComponents/banner"
import InquireSection from "../../Components/inquirelink"

import SEO from "../../Components/SEO"
const ContentContainer = ({ title, image, children }) => {
    return (
        <Layout>
            <SEO title={title} description="Fastlink offers a wide variety of products,services, and solutions that will cater all the consumer and enterprise's needs, wants, and requirements" image={image}></SEO>
            <Banner title={title} image={image} />
            {children}
            <InquireSection />
        </Layout>

    )
}


export default ContentContainer
