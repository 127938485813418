import React from "react"

import { Container, Button } from "reactstrap"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import "../assets/css/inquire.css"
import { Link } from "gatsby"


const InquireSection = props => {

  const data = useStaticQuery(graphql`
    {
      allFile(filter: {relativePath: {eq: "background/inquire.png"}}) {
        nodes {
            childImageSharp {
            fluid {
                ...GatsbyImageSharpFluid
            }
            id
            }
        }
      }
    }
  `)
  const imageData = data.allFile.nodes[0].childImageSharp.fluid

  return (
    <BackgroundImage
      Tag="section"
      fluid={imageData}
      style={{
        paddingTop: "20vh",
        paddingBottom: "20vh"
      }}
    >
      <Container className="d-flex flex-column my-auto text-center inquireContainer">
        <h1 className="heading1"><span>Inquire Now</span></h1>
        <h2 className="heading2">Link Faster With Us</h2>
        <p className="text3">How can we help? We'd like to hear from you!</p>
        <div>
          {props.nobutton ? <div /> : <Link to="/contact"><Button className="w-4 contact-button">Contact Us</Button></Link>}

        </div>
      </Container>

    </BackgroundImage>
  )
}

export default InquireSection
